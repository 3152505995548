<template>
    <div class="px-[1rem] lg:px-[0rem] mt-[5rem] container mx-auto">
        <h2 class="text-[36px] font-bold">Let’s Sign You In</h2>
        <p class="text-[24px] mt-3 leading-[34px] opacity-50">Welcome back, to <br class="sm:block md:hidden" />Digital
            Permit Book!</p>
        <form @submit.prevent="handleFormSubmit">
            <div class="relative mt-8" v-if="loginWithUsername">
                <input type="text" id="comp-id"
                    class="rounded-[16px] block px-2.5 pb-2.5 pt-4 w-full text-[12px] bg-transparent rounded-lg border border-[#DADADA] appearance-none focus:outline-none focus:ring-0 focus:border-[#2D69F6] peer"
                    placeholder="" required :disabled="hideCompID" v-model="user.compID"/>
                <label for="comp-id"
                    class="absolute text-[12px] text-[#DADADA] duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1">Enter
                    Comp ID</label>
            </div>
            <div class="relative mt-8" v-if="!loginWithUsername">
                <input type="email" id="email"
                    class="rounded-[16px] block px-2.5 pb-2.5 pt-4 w-full text-[12px] bg-transparent rounded-lg border border-[#DADADA] appearance-none focus:outline-none focus:ring-0 focus:border-[#2D69F6] peer"
                    placeholder="" required v-model="user.username" />
                <label for="email"
                    class="absolute text-[12px] text-[#DADADA] duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1">Enter
                    Email</label>
            </div>
            <div class="relative mt-5" v-else>
                <input type="text" id="username"
                    class="rounded-[16px] block px-2.5 pb-2.5 pt-4 w-full text-[12px] bg-transparent rounded-lg border border-[#DADADA] appearance-none focus:outline-none focus:ring-0 focus:border-[#2D69F6] peer"
                    placeholder="" required v-model="user.username" />
                <label for="username"
                    class="absolute text-[12px] text-[#DADADA] duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1">Enter
                    Username</label>
            </div>
            <div class="relative mt-5" v-if="(!hidePassword && loginWithUsername) || !loginWithUsername">
                <div class="absolute z-[11] inset-y-0 flex right-[10px] items-center ps-3 cursor-pointer"
                    @click="showPassword = !showPassword">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.2">
                            <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="black"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                    </svg>

                </div>
                <input :type="showPassword ? 'text' : 'password'" id="password"
                    class="rounded-[16px] block px-2.5 pb-2.5 pt-4 w-full text-[12px] bg-transparent rounded-lg border border-[#DADADA] appearance-none focus:outline-none focus:ring-0 focus:border-[#2D69F6] peer"
                    placeholder="" required v-model="user.password" />
                <label for="password"
                    class="absolute text-[12px] text-[#DADADA] duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1">Enter
                    Password</label>
            </div>
            <div class="mt-5">
                <button type="submit"
                    class="text-[#fff] h-[56px] text-[14px] flex justify-center w-full rounded-[16px] bg-[#2D69F6] items-center">Login</button>
            </div>
            <div class="mt-5" v-if="!hidePassword && !hideCompID">
                <button type="button" @click="loginWithUsername = !loginWithUsername"
                    class="text-[#000] h-[56px] text-[14px] flex justify-center w-full rounded-[16px] bg-transparent border border-[#DADADA] items-center">Login
                    with {{ loginWithUsername ? 'Email' : 'Username' }}</button>
            </div>
            <div>
            <div class="my-5 text-center" v-if="!hidePassword && !hideCompID">
            <router-link to="/forgot-password" class="text-[14px] text-[#2D69F6]">Forget Password ?</router-link>
            </div>
            <div class="mt-5 text-center">
                <router-link to="/help" class="text-[14px] flex items-center justify-center"><span class="text-[#2D69F6]">Get Help</span></router-link>
            </div>
            </div>
        </form>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "LoginComponent",
    data() {
        return {
            showPassword: false,
            hideCompID: false,
            hidePassword: false,
            loginWithUsername: true,
            user: {
                username: "",
                compID: "",
                password: "",
            },
        }
    },
    watch:{
        loginWithUsername(){
            this.showPassword = false
            this.user = {
                username: "",
                compID: "",
                password: "",
            }
            if(this.loginWithUsername) this.checkAuthParams()
        }
    },  
    methods: {
        ...mapActions(["loginUser", "setNotification"]),
        async handleFormSubmit() {
            try {
                const { key } = await this.loginUser({
                    payload: {
                        ...(!this.loginWithUsername
                            ? { email: this.user.username }
                            : { username: this.user.username+"."+this.user.compID }),
                        password: this.user.password
                    }
                });
                if (key) {
                    this.setNotification({
                        msg: "Succesfully Login",
                        type: "success",
                        color: "green",
                    })
                    this.$router.push("/")
                }
            }
            catch (error) {
                this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });

            }
        },
        checkAuthParams(){
            this.hideCompID = false
            this.hidePassword = false
            if(this.$route.query.compid){
                this.user.compID = this.$route.query.compid
                this.hideCompID = true
            }
            if(this.$route.query.password){
            this.user.password = this.$route.query.password
            this.hidePassword = true
        }
        }
    },
    mounted(){
        this.checkAuthParams()
    }
}
</script>