<template>
    <div id="already-assign-equipment-modal" tabindex="-1" aria-hidden="true"
        class="bg-black bg-opacity-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100vh] max-h-full flex">
        <div class="relative w-[312px] max-h-full bg-white rounded-[16px]">
            <!-- Modal content -->
            <div class="relative bg-white shadow rounded-[16px]">
                <div class="p-4 md:p-5 text-center">
                    <svg class="mx-auto" width="46" height="48" viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_8765_2404)">
                            <path
                                d="M39.4739 45.0668H6.52503C4.26377 45.0668 2.1633 43.8718 0.974569 41.9081C-0.214516 39.9448 -0.322646 37.493 0.688795 35.4283L17.1632 6.63908C18.2688 4.38299 20.5279 2.95752 22.9995 2.95752C25.471 2.95752 27.7302 4.38299 28.8357 6.63908L45.3102 35.4283C46.3216 37.493 46.2135 39.9448 45.0244 41.9081C43.8357 43.8718 41.7355 45.0668 39.4739 45.0668Z"
                                fill="#F0C419" />
                            <path
                                d="M39.4778 45.067H22.9995V2.95765C25.477 2.94188 27.7453 4.37308 28.8389 6.64243L45.3091 35.4305C46.322 37.4939 46.2153 39.9456 45.0272 41.9089C43.8389 43.8726 41.7391 45.0677 39.4778 45.067Z"
                                fill="#F29C1F" />
                            <path
                                d="M26.1732 37.7786C26.1732 39.5677 24.7521 41.0179 22.9995 41.0179C21.247 41.0179 19.8262 39.5677 19.8262 37.7786C19.8262 35.9897 21.247 34.5396 22.9995 34.5396C24.7521 34.5396 26.1732 35.9897 26.1732 37.7786Z"
                                fill="#35495E" />
                            <path
                                d="M26.1732 37.7786C26.1732 38.6379 25.839 39.4617 25.2439 40.0694C24.6485 40.6768 23.8414 41.0179 22.9995 41.0179V34.5396C23.8414 34.5392 24.6485 34.8803 25.2439 35.4881C25.839 36.0955 26.1732 36.9196 26.1732 37.7786Z"
                                fill="#2C3E50" />
                            <path
                                d="M22.9995 9.43604C24.7521 9.43604 26.1732 10.8862 26.1732 12.6754V28.0613C26.1732 29.8502 24.7521 31.3004 22.9995 31.3004C21.247 31.3004 19.8262 29.8502 19.8262 28.0613V12.6754C19.8262 10.8862 21.247 9.43604 22.9995 9.43604Z"
                                fill="#35495E" />
                            <path
                                d="M26.1732 12.6754V28.0613C26.1732 28.9206 25.839 29.7445 25.2439 30.3518C24.6485 30.9596 23.8414 31.3007 22.9995 31.3004V9.43604C23.8414 9.43496 24.6496 9.77574 25.245 10.3835C25.84 10.9912 26.1743 11.8158 26.1732 12.6754Z"
                                fill="#2C3E50" />
                        </g>
                        <defs>
                            <clipPath id="clip0_8765_2404">
                                <rect width="46" height="46.9518" fill="white" transform="translate(0 0.496582)" />
                            </clipPath>
                        </defs>
                    </svg>

                    <h2 class="font-bold text-[20px] text-[#11004D] mt-3">Another driver is currently using this {{
                        equipment }}!</h2>
                    <p class="text-[#11004D] opacity-50 mt-2">Assigning this {{ equipment }} will remove the other driver.
                        Are
                        you sure ?</p>
                </div>
                <!-- Modal footer -->
                <div class="p-4">
                    <button
                        class="text-[#fff] h-[48px] text-[14px] flex justify-center w-full rounded-[12px] bg-[#2D69F6] items-center"
                        @click="setAlreadyAssignEquipmentModal(true)"
                        >Confirm</button>
                    <button type="button"
                        class="text-[#000] mt-3 h-[48px] text-[14px] flex justify-center w-full rounded-[12px] bg-transparent border border-[#DADADA] items-center mb-3"
                        @click="setAlreadyAssignEquipmentModal()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "AlreadyAssignEquipmentComponent",
    props: ['equipment', 'setAlreadyAssignEquipmentModal']
}
</script>