const state = {
    loader: false,
  };
  
  const getters = {
    loader: (state) => state.loader,
  };
  
  const actions = {
    async setLoader({ commit }, loader) {
      if (!loader) {
        setTimeout(() => {
          commit("newLoader", loader);
        }, 500);
      } else {
        commit("newLoader", loader);
      }
    },
  };
  
  const mutations = {
    newLoader: (state, loader) => (state.loader = loader),
  };
  
  export default {
    state,
    getters,
    actions,
    mutations,
  };
  