<template>
    <div>
        <div v-if="user">
            <div class="px-[1rem] lg:px-[0rem] mt-[1rem]">
                <div class="flex items-center">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_8765_4795)">
                            <path
                                d="M12.0001 13.428C13.9726 13.428 15.5716 11.829 15.5716 9.85658C15.5716 7.88414 13.9726 6.28516 12.0001 6.28516C10.0277 6.28516 8.42871 7.88414 8.42871 9.85658C8.42871 11.829 10.0277 13.428 12.0001 13.428Z"
                                stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M5.90015 18.9999C6.53763 17.9535 7.43359 17.0886 8.50187 16.4886C9.57016 15.8884 10.7748 15.5732 12.0001 15.5732C13.2254 15.5732 14.4301 15.8884 15.4984 16.4886C16.5667 17.0886 17.4626 17.9535 18.1002 18.9999"
                                stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M12.0001 21.2853C17.1285 21.2853 21.2858 17.128 21.2858 11.9996C21.2858 6.87122 17.1285 2.71387 12.0001 2.71387C6.87171 2.71387 2.71436 6.87122 2.71436 11.9996C2.71436 17.128 6.87171 21.2853 12.0001 21.2853Z"
                                stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_8765_4795">
                                <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div class="ml-5 w-full">
                        <p class="text-[14px] opacity-50">Name</p>
                        <p class="text-[#1B1D21]">{{ user.first_name }} {{ user.last_name }}</p>
                        <hr class="mt-3">
                    </div>
                </div>
                <div class="flex mt-5 items-center">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_8765_4759)">
                            <path
                                d="M20.0601 5.2373H3.94007C3.26313 5.2373 2.71436 5.78608 2.71436 6.46302V17.5373C2.71436 18.2142 3.26313 18.763 3.94007 18.763H20.0601C20.737 18.763 21.2858 18.2142 21.2858 17.5373V6.46302C21.2858 5.78608 20.737 5.2373 20.0601 5.2373Z"
                                stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.3706 10.3994H18.2089" stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M15.3706 13.2119H18.2089" stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path
                                d="M7.01025 10.8684C7.01025 12.163 8.0597 13.2124 9.35425 13.2124C9.68152 13.2124 9.99313 13.1453 10.2761 13.0242C11.1123 12.6661 11.6982 11.8357 11.6982 10.8684C11.6982 9.57386 10.6488 8.52441 9.35425 8.52441C8.0597 8.52441 7.01025 9.57386 7.01025 10.8684Z"
                                stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M5.79126 15.5337C6.16372 14.8253 6.68719 14.2398 7.31135 13.8335C7.9355 13.4273 8.63936 13.2139 9.35525 13.2139C10.0711 13.2139 10.775 13.4273 11.3992 13.8335C12.0233 14.2398 12.5468 14.8253 12.9192 15.5337"
                                stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_8765_4759">
                                <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div class="ml-5 w-full">
                        <p class="text-[14px] opacity-50">License No.</p>
                        <p class="text-[#1B1D21]">{{ user.team_member.license_number }}</p>
                        <hr class="mt-3">
                    </div>
                </div>
                <div class="flex mt-5 items-center">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_8765_4772)">
                            <rect width="20" height="20" transform="translate(1.99976 2)" fill="white" />
                            <g clip-path="url(#clip1_8765_4772)">
                                <path
                                    d="M21.4141 5.75259H20.7865C20.631 5.75259 20.4821 5.81439 20.3722 5.92426L17.4057 8.89056H16.8508L16.3341 6.82407C16.269 6.56314 16.0346 6.38019 15.7657 6.38019H14.4689V5.71094C14.4689 5.3873 14.2064 5.125 13.8829 5.125H3.84114C3.58891 5.125 3.36507 5.28644 3.28526 5.5257L2.03007 9.29126C2.00124 9.37747 1.993 9.46933 2.00597 9.55935L2.63356 13.9527C2.65553 14.1065 2.73778 14.2453 2.86198 14.3386L5.37251 16.2213C5.47383 16.2973 5.59727 16.3385 5.72407 16.3385H7.99168L8.44761 16.7945C8.55763 16.9043 8.70656 16.9661 8.86204 16.9661H9.80366L10.8849 18.588C10.9935 18.7509 11.1765 18.8489 11.3724 18.8489H12.6276C12.8798 18.8489 13.1037 18.6876 13.1835 18.4482L13.6776 16.9661H14.1967C14.4185 16.9661 14.6215 16.8407 14.7207 16.6422L14.8726 16.3385H16.7781L18.4894 18.0498C18.7181 18.2785 19.0891 18.2785 19.318 18.0498L20.5732 16.7945C20.7708 16.597 20.8013 16.2876 20.6464 16.0552L19.8192 14.8143C19.7255 14.6738 19.7442 14.4855 19.8636 14.366L20.5732 13.6565C20.683 13.5466 20.7448 13.3975 20.7448 13.2422V11.4977L21.9382 9.11105C21.979 9.02957 22 8.93985 22 8.84891V6.33853C22 6.01489 21.7377 5.75259 21.4141 5.75259ZM20.8281 8.71066L19.6347 11.0973C19.594 11.1788 19.5729 11.2683 19.5729 11.3594V12.9994L19.0352 13.5372C18.5218 14.0502 18.4415 14.8607 18.8442 15.4644L19.4049 16.3056L18.9037 16.8068L17.4352 15.3383C17.3253 15.2285 17.1762 15.1667 17.0208 15.1667H14.5104C14.2885 15.1667 14.0856 15.2921 13.9862 15.4906L13.8344 15.7942H13.2552C13.003 15.7942 12.7791 15.9557 12.6993 16.1949L12.2052 17.6772H11.686L10.6047 16.0552C10.4961 15.8922 10.3132 15.7942 10.1172 15.7942H9.10466L8.64872 15.3383C8.53886 15.2285 8.38978 15.1667 8.23445 15.1667H5.91923L3.75935 13.5466L3.18562 9.53067L4.2635 6.29687H13.2969V6.96612C13.2969 7.28976 13.5593 7.55206 13.8828 7.55206H15.3081L15.8248 9.61871C15.8899 9.87948 16.1243 10.0624 16.3932 10.0624H17.6485C17.8038 10.0624 17.9529 10.0008 18.0628 9.89093L20.8281 7.12543V8.71066Z"
                                    fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            </g>
                        </g>
                        <defs>
                            <clipPath id="clip0_8765_4772">
                                <rect width="20" height="20" fill="white" transform="translate(1.99976 2)" />
                            </clipPath>
                            <clipPath id="clip1_8765_4772">
                                <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div class="ml-5 w-full">
                        <p class="text-[14px] opacity-50">License State</p>
                        <p class="text-[#1B1D21]">{{ user.team_member.license_state.name }}</p>
                        <hr class="mt-3">
                    </div>
                </div>
            </div>
            <div class="flex border-[#E6E8EC] border-b items-center px-[1rem] lg:px-[0rem] py-3 cursor-pointer" @click="openDoc(doc.id)"
                :class="index === 0 ? 'border-t mt-5' : ''" :key="index" v-for="(doc, index) in docs">
                <div class="bg-[#EAF0FE] rounded-[50%] p-3 flex items-center justify-center w-[40px] h-[40px]">
                    <svg width="17" height="22" viewBox="0 0 17 22" v-if="doc.document.includes('.pdf')" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.53368 13.4727C4.33818 13.4727 4.20643 13.4918 4.13843 13.5109V14.7625C4.21918 14.7817 4.32011 14.787 4.4593 14.787C4.96824 14.787 5.28168 14.5298 5.28168 14.0953C5.28168 13.7064 5.0118 13.4727 4.53368 13.4727ZM8.23859 13.4854C8.02609 13.4854 7.88797 13.5045 7.80616 13.5237V16.2968C7.88797 16.3159 8.01972 16.3159 8.13872 16.3159C9.00678 16.3223 9.57203 15.8442 9.57203 14.8327C9.57841 13.9508 9.06309 13.4854 8.23859 13.4854Z"
                            fill="#2D69F6" />
                        <path
                            d="M10.625 0H2.125C1.56142 0 1.02091 0.223879 0.622402 0.622402C0.22388 1.02091 0 1.56142 0 2.125V19.125C0 19.6885 0.22388 20.229 0.622402 20.6276C1.02091 21.0261 1.56142 21.25 2.125 21.25H14.875C15.4385 21.25 15.979 21.0261 16.3776 20.6276C16.7761 20.229 17 19.6885 17 19.125V6.375L10.625 0ZM5.84163 15.0769C5.51331 15.385 5.02881 15.5231 4.46462 15.5231C4.35523 15.5243 4.24589 15.5179 4.13737 15.504V17.0191H3.1875V12.8371C3.61617 12.7732 4.04931 12.7441 4.48269 12.75C5.0745 12.75 5.49525 12.8626 5.77894 13.0889C6.04881 13.3036 6.23156 13.6552 6.23156 14.0696C6.2305 14.4861 6.09238 14.8378 5.84163 15.0769ZM9.88656 16.5166C9.44031 16.8874 8.76138 17.0637 7.93156 17.0637C7.43431 17.0637 7.08262 17.0319 6.84356 17V12.8382C7.27239 12.7756 7.70536 12.7461 8.13875 12.75C8.94306 12.75 9.46581 12.8945 9.87381 13.2026C10.3148 13.5299 10.591 14.0516 10.591 14.8006C10.591 15.6113 10.2946 16.1712 9.88656 16.5166ZM13.8125 13.5681H12.1848V14.5361H13.7062V15.3159H12.1848V17.0202H11.2221V12.7819H13.8125V13.5681ZM10.625 7.4375H9.5625V2.125L14.875 7.4375H10.625Z"
                            fill="#2D69F6" />
                    </svg>
                    <svg v-else width="17" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.75 0H2.15C1.57979 0 1.03292 0.226513 0.629724 0.629724C0.226513 1.03292 0 1.57979 0 2.15V19.35C0 19.9202 0.226513 20.467 0.629724 20.8703C1.03292 21.2735 1.57979 21.5 2.15 21.5H15.05C15.6202 21.5 16.167 21.2735 16.5703 20.8703C16.9735 20.467 17.2 19.9202 17.2 19.35V6.45L10.75 0ZM5.63193 15.5294C5.63193 16.7678 5.0396 17.2 4.08607 17.2C3.86032 17.2 3.56362 17.1613 3.3712 17.0957L3.47977 16.3035C3.6163 16.3486 3.79045 16.3809 3.98395 16.3809C4.39675 16.3809 4.65368 16.1938 4.65368 15.5166V12.7828H5.633L5.63193 15.5294ZM9.17297 15.1371C8.8365 15.4521 8.342 15.595 7.7615 15.595C7.6325 15.595 7.5164 15.5875 7.4261 15.5746V17.128H6.45215V12.8409C6.89182 12.7747 7.33623 12.7449 7.78085 12.7517C8.38607 12.7517 8.81822 12.8667 9.1074 13.0989C9.38583 13.3182 9.57287 13.6794 9.57287 14.1051C9.57287 14.5308 9.4299 14.892 9.17297 15.1371ZM13.8288 16.9291C13.5278 17.0323 12.9527 17.1742 12.3786 17.1742C11.5863 17.1742 11.0123 16.9743 10.6124 16.5873C10.2136 16.2132 9.99427 15.6466 9.99965 15.0081C10.0072 13.5633 11.0564 12.7388 12.4818 12.7388C13.0419 12.7388 13.4751 12.8495 13.6869 12.9516L13.4816 13.7374C13.2429 13.6342 12.9473 13.5504 12.47 13.5504C11.6519 13.5504 11.0316 14.0148 11.0316 14.9565C11.0316 15.852 11.5939 16.3809 12.398 16.3809C12.6248 16.3809 12.8043 16.3551 12.8828 16.3153V15.4058H12.212V14.6394H13.8288V16.9291ZM10.75 7.525H9.675V2.15L15.05 7.525H10.75Z"
                            fill="#2D69F6" />
                        <path
                            d="M7.8313 13.4932C7.63135 13.4932 7.4959 13.5125 7.42603 13.5319V14.8143C7.5088 14.8337 7.61308 14.8391 7.75605 14.8391C8.27635 14.8391 8.59885 14.5746 8.59885 14.1306C8.59885 13.7307 8.32258 13.4932 7.8313 13.4932Z"
                            fill="#2D69F6" />
                    </svg>

                </div>
                <div class="ml-5">
                    <p class="font-bold">{{ doc.description }}</p>
                    <p class="text-[12px]" :class="doc.expiry_date ? getExpiryStatus(doc.expiry_date) : ''">
                        {{
                            doc.expiry_date
                            ? "Expiring " +
                            moment(doc.expiry_date).format("MMM DD, YYYY")
                            : "-"
                        }}

                    </p>
                </div>
                <svg width="20" class="ml-auto cursor-pointer" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_8765_5184)">
                        <path
                            d="M10 0C4.48582 0 0 4.48582 0 10C0 15.5142 4.48582 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 0 10 0ZM13.0892 10.5892L8.9225 14.7558C8.76 14.9183 8.54668 15 8.33332 15C8.12 15 7.90664 14.9183 7.74414 14.7558C7.41832 14.43 7.41832 13.9033 7.74414 13.5775L11.3217 10L7.74418 6.4225C7.41836 6.09668 7.41836 5.57 7.74418 5.24418C8.07 4.91836 8.59668 4.91836 8.9225 5.24418L13.0892 9.41086C13.415 9.73668 13.415 10.2633 13.0892 10.5892Z"
                            fill="#2D69F6" />
                    </g>
                    <defs>
                        <clipPath id="clip0_8765_5184">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as moment from "moment-timezone";
export default {
    name: "DriverDetailsComponent",
    data() {
        return {
            moment
        }
    },
    computed: {
        ...mapGetters(['user', 'company']),
        docs(){
            if(this.company && this.company.show_dot_mode && this.user && this.user.team_member.start_dot_mode) return this.user.team_member.docs.filter((doc)=>doc.dot_mode)
            return this.user.team_member.docs
        },
    },
    methods: {
        ...mapActions(['retrieveDocument']),
        async openDoc(id) {
            const doc = await this.retrieveDocument(id);
            window.location.href = doc.document
        },
        getExpiryStatus(date) {
            const currentDate = new Date();
            const expiryDate = new Date(date);
            const diffDays = parseInt(
                (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
                10
            );
            let color;
            if (diffDays > 31) {
                color = "text-[#23262F]";
            }
            else if (diffDays <= 31 && diffDays >= 1) {
                color = "text-[#FEC701]";
            }
            else color = "text-[#FF0000]";
            return color
        }
    }
}
</script>