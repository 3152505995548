import { getReq } from "../../../services/api";
const state = {
  currentVehicle: null,
  currentTrailer: null
};

const getters = {
  currentVehicle: (state) => state.currentVehicle,
  currentTrailer: (state) => state.currentTrailer
};

const actions = {
  async getEquipmentData({ commit }, { id, companyID, type, setStore = true }) {
    try {
      const currentEquipment = await getReq(`app/equipment/${id}/?company_id=${companyID}`);
      if (setStore) {
        if (type === 'vehicle') commit("setCurrentVehicle", currentEquipment);
        else commit("setCurrentTrailer", currentEquipment);
      }
      return currentEquipment
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getAllEquipments(_, { url, companyID }) {
    try {
      const data = await getReq(`app/equipment/${url ? "?" + url : ""}${companyID ? `${url ? '&' : '?'}company_id=${companyID}` : ''}`);
      return { records: data.equipment, total_pages: data.total_pages, queryset_count: data.queryset_count, total_entries: data.total_entries_count, current_page: data.current_page }
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
};

const mutations = {
  setCurrentVehicle: (state, vehicle) => (state.currentVehicle = vehicle),
  setCurrentTrailer: (state, trailer) => (state.currentTrailer = trailer),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
