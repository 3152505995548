import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/auth/Login"
import Website from "@/views/Website"
import Dashboard from "@/views/Website/Dashboard"
import Equipment from "@/views/Website/Equipment"
import Company from "@/views/Website/Company"
import SearchEquipment from "@/views/Website/SearchEquipment"
import Documents from "@/views/Website/Documents"
import EmailDocuments from "@/views/Website/EmailDocuments"
import ForgotPassword from "@/views/auth/ForgotPassword"
import DriverDetails from "@/views/Website/DriverDetails"
import Help from "@/views/auth/Help"
import EquipmentDetails from "@/views/Website/EquipmentDetails"
import Contacts from "@/views/Website/Contacts"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Website,
    name: "Website",
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/vehicle",
        name: "Vehicle",
        component: Equipment,
      },
      {
        path: "/trailer",
        name: "Trailer",
        component: Equipment,
      },
      {
        path: "/company",
        name: "Company",
        component: Company
      },
      {
        path: "/driver-details",
        name: "Driver",
        component: DriverDetails
      },
      {
        path: "/search-vehicles",
        name: "Vehicles",
        component: SearchEquipment
      },
      {
        path: "/search-trailers",
        name: "Trailers",
        component: SearchEquipment
      },
      {
        path: "/documents",
        name: "Documents",
        component: Documents
      },
      {
        path: "/email-documents",
        name: "Email",
        component: EmailDocuments
      },
      {
        path: "/vehicle-details/:id",
        name: "Vehicle",
        component: EquipmentDetails
      },
      {
        path: "/trailer-details/:id",
        name: "Trailer",
        component: EquipmentDetails
      },
      {
        path: "/contacts",
        name: "Contacts",
        component: Contacts
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
  },
  
  { path: "*", redirect: "/" }
]
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.name === "Login" || to.name === "ForgotPassword") {
    if (localStorage.getItem("driver-token")) {
      next({ name: "Dashboard" });
    } else next();
  }
  else {
    if (!localStorage.getItem("driver-token") && to.name !== "Help" ) {
      next({ name: "Login" });
      next();
    }
    else next();
  }
})

export default router;
