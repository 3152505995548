<template>
    <div>
        <div class="px-[1rem] lg:px-[0rem] mt-[1rem]">
            <div class="relative my-3">
                <input placeholder="Search for a contact" v-model="searchContacts"
                    class="rounded-[12px] text-[#1B1D21] border border-[rgba(17,0,77,0.1)] px-3 py-4 w-full placeholder-[rgba(17,0,77,0.1)] focus:outline-none">
                <svg class="absolute top-[20px] right-[15px]" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_8765_2602)">
                        <path
                            d="M19.8105 18.9119L14.6468 13.8308C15.999 12.3616 16.8298 10.4187 16.8298 8.28068C16.8292 3.7071 13.0621 0 8.41471 0C3.76737 0 0.000244141 3.7071 0.000244141 8.28068C0.000244141 12.8543 3.76737 16.5614 8.41471 16.5614C10.4227 16.5614 12.2644 15.8668 13.711 14.7122L18.8947 19.8134C19.1473 20.0622 19.5573 20.0622 19.8099 19.8134C20.0631 19.5646 20.0631 19.1607 19.8105 18.9119ZM8.41471 15.2873C4.48256 15.2873 1.29493 12.1504 1.29493 8.28068C1.29493 4.41101 4.48256 1.27403 8.41471 1.27403C12.3469 1.27403 15.5345 4.41101 15.5345 8.28068C15.5345 12.1504 12.3469 15.2873 8.41471 15.2873Z"
                            fill="#E7E5ED" />
                    </g>
                    <defs>
                        <clipPath id="clip0_8765_2602">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <svg width="2" class="absolute top-[13px] right-[50px]" height="32" viewBox="0 0 2 32" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.1" d="M1 0V32" stroke="#11004D" />
                </svg>
            </div>
        </div>
        <div class="mt-3">
            <div class="bg-[#EAF0FE] border-b border-t border-[#F1F0F4] py-3 flex mt-5 px-[1rem] lg:px-3">
                <p class="mb-0 text-[18px] text-[#1B1D21] font-bold">Name</p>
            </div>
            <div class="flex items-center justify-between border-b border-[#F1F0F4] mt-3 pb-3
            px-[1rem] lg:px-[0rem] mt-[1rem]
            " v-for="(contact, index) in companyContacts" :key="index">
            <div class="flex items-center">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20" r="20" fill="#EAF0FE" />
                    <path
                        d="M20.0001 21.54C22.1243 21.54 23.8462 19.818 23.8462 17.6938C23.8462 15.5696 22.1243 13.8477 20.0001 13.8477C17.8759 13.8477 16.1539 15.5696 16.1539 17.6938C16.1539 19.818 17.8759 21.54 20.0001 21.54Z"
                        stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M13.4305 27.5379C14.1171 26.411 15.0819 25.4796 16.2324 24.8334C17.3829 24.1871 18.6802 23.8477 19.9998 23.8477C21.3193 23.8477 22.6167 24.1871 23.7671 24.8334C24.9176 25.4796 25.8824 26.411 26.569 27.5379"
                        stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M20 30C25.5229 30 30 25.5229 30 20C30 14.4772 25.5229 10 20 10C14.4772 10 10 14.4772 10 20C10 25.5229 14.4772 30 20 30Z"
                        stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div class="ml-5">
                    <p class="text-[#1B1D21] mb-0 text-[16px]">{{ contact.name }}</p>
                    <p class="text-[#1B1D21] text-[13px] opacity-50 mb-3">{{ contact.department }}</p>
                    <p class="text-[#1B1D21] text-[13px] opacity-50">{{ contact.phone }} <span class="ml-2">Ext. {{
                        contact.ext_no }}</span>
                    </p>
                    <p class="text-[#1B1D21] text-[13px] opacity-50">{{ contact.email }}</p>
                </div>
            </div>
                <div class="flex justify-between flex-col h-[70px]">
                    <a :href="`tel:${contact.phone}${contact.ext_no ? `,${contact.ext_no}`:''}`">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_7608_3121)">
                            <path
                                d="M7.49683 18.5452C6.61892 19.1115 5.57276 19.3584 4.53426 19.2442C3.49578 19.1299 2.52828 18.6616 1.79448 17.9179L1.15295 17.2906C0.871708 17.0029 0.714233 16.6166 0.714233 16.2144C0.714233 15.8121 0.871708 15.4256 1.15295 15.1381L3.87583 12.4437C4.1611 12.1633 4.54506 12.0062 4.94503 12.0062C5.34499 12.0062 5.72896 12.1633 6.01422 12.4437C6.30189 12.7249 6.68823 12.8824 7.09055 12.8824C7.49286 12.8824 7.87919 12.7249 8.16686 12.4437L12.4436 8.16689C12.5863 8.02629 12.6996 7.85873 12.7769 7.67396C12.8543 7.48919 12.8941 7.29087 12.8941 7.09057C12.8941 6.89026 12.8543 6.69196 12.7769 6.50717C12.6996 6.3224 12.5863 6.15484 12.4436 6.01424C12.1633 5.72899 12.0062 5.34502 12.0062 4.94506C12.0062 4.54509 12.1633 4.16113 12.4436 3.87586L15.1522 1.16724C15.4399 0.885989 15.8262 0.728516 16.2285 0.728516C16.6309 0.728516 17.0172 0.885989 17.3049 1.16724L17.9322 1.80876C18.6758 2.54256 19.1441 3.51006 19.2584 4.54854C19.3727 5.58704 19.1258 6.6332 18.5594 7.51112C15.6084 11.8602 11.8535 15.6054 7.49683 18.5452Z"
                                stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_7608_3121">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
                <a :href="`mailto:${contact.email}`"> 
                    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19.4615 1H2.53846C1.68879 1 1 1.6888 1 2.53846V15.6154C1 16.4651 1.68879 17.1538 2.53846 17.1538H19.4615C20.3112 17.1538 21 16.4651 21 15.6154V2.53846C21 1.6888 20.3112 1 19.4615 1Z"
                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M1 2.92383L10.0154 8.34574C10.2918 8.50803 10.6402 8.59691 11 8.59691C11.3598 8.59691 11.7082 8.50803 11.9846 8.34574L21 2.92383"
                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: "ContactsComponent",
    data() {
        return {
            searchContacts: "",
            contacts: []
        }
    },
    computed: {
        ...mapGetters(['user']),
        companyContacts() {
            if (this.searchContacts) return this.contacts.filter(contact => contact.department.toLowerCase().includes(this.searchContacts.toLowerCase()) || contact.name.toLowerCase().includes(this.searchContacts.toLowerCase()));
            else return this.contacts
        }
    },
    methods: {
        ...mapActions(['getAllContacts', 'setLoader']),
        async getContacts() {
            this.setLoader(true)
            try {
                this.contacts = await this.getAllContacts(this.user.team_member.member_company.id)
                this.setLoader(false)
            }
            catch (e) {
                console.log(e)
                this.setLoader(false)
            }
        }
    },
    mounted() {
        this.getContacts()
    }
}
</script>