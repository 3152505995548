<template>
    <div class="container mx-auto relative">
        <svg class="absolute top-[-40px] left-[10px] lg:left-[-5px] cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg" @click="$router.go(-1)">
            <path
                d="M18.6661 10.6666H8.54191L12.9437 6.28357C13.1949 6.03271 13.336 5.69246 13.336 5.33769C13.336 4.98291 13.1949 4.64266 12.9437 4.3918C12.6926 4.14093 12.3519 4 11.9967 4C11.6415 4 11.3008 4.14093 11.0496 4.3918L4.38018 11.053C4.25874 11.1797 4.16355 11.3291 4.10006 11.4926C3.96665 11.817 3.96665 12.1808 4.10006 12.5051C4.16355 12.6686 4.25874 12.818 4.38018 12.9448L11.0496 19.6059C11.1736 19.7308 11.3211 19.8299 11.4837 19.8975C11.6462 19.9652 11.8206 20 11.9967 20C12.1728 20 12.3471 19.9652 12.5097 19.8975C12.6722 19.8299 12.8197 19.7308 12.9437 19.6059C13.0688 19.4821 13.168 19.3347 13.2357 19.1724C13.3034 19.01 13.3383 18.8359 13.3383 18.66C13.3383 18.4842 13.3034 18.31 13.2357 18.1477C13.168 17.9853 13.0688 17.838 12.9437 17.7142L8.54191 13.3311H18.6661C19.0199 13.3311 19.3592 13.1907 19.6093 12.9409C19.8595 12.6911 20 12.3522 20 11.9989C20 11.6455 19.8595 11.3067 19.6093 11.0568C19.3592 10.807 19.0199 10.6666 18.6661 10.6666Z"
                fill="black" />
        </svg>
        <div class="px-[1rem] lg:px-[0rem] mt-[5rem]">
            <h2 class="text-[36px] font-bold">Help</h2>
            <p class="text-[24px] mt-3 leading-[34px] opacity-50">Let us help you, to <br
                    class="sm:block md:hidden" />better use your app.</p>
            <a class="flex border-[#E6E8EC] border-b border-t items-center px-[1rem] py-[1.5rem] cursor-pointer mt-8"
                href="https://cdn.prod.website-files.com/62d3f7cf127ec8f3299999a3/671baf13b641af6e014da14b_DOTInspectionCard.pdf"
                target="_blank">
                <div>
                    <p class="mb-0">DOT Inspection Card</p>
                </div>
                <svg width="20" class="ml-auto cursor-pointer" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_8765_5184)">
                        <path
                            d="M10 0C4.48582 0 0 4.48582 0 10C0 15.5142 4.48582 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 0 10 0ZM13.0892 10.5892L8.9225 14.7558C8.76 14.9183 8.54668 15 8.33332 15C8.12 15 7.90664 14.9183 7.74414 14.7558C7.41832 14.43 7.41832 13.9033 7.74414 13.5775L11.3217 10L7.74418 6.4225C7.41836 6.09668 7.41836 5.57 7.74418 5.24418C8.07 4.91836 8.59668 4.91836 8.9225 5.24418L13.0892 9.41086C13.415 9.73668 13.415 10.2633 13.0892 10.5892Z"
                            fill="#2D69F6" />
                    </g>
                    <defs>
                        <clipPath id="clip0_8765_5184">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </a>
            <a class="flex border-[#E6E8EC] border-b items-center px-[1rem] py-[1.5rem] cursor-pointer"
                href="https://cdn.prod.website-files.com/62d3f7cf127ec8f3299999a3/671baf2f574b8421cf697ced_DriverUserGuide.pdf"
                target="_blank">
                <div>
                    <p class="mb-0">User Guide</p>
                </div>
                <svg width="20" class="ml-auto cursor-pointer" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_8765_5184)">
                        <path
                            d="M10 0C4.48582 0 0 4.48582 0 10C0 15.5142 4.48582 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 0 10 0ZM13.0892 10.5892L8.9225 14.7558C8.76 14.9183 8.54668 15 8.33332 15C8.12 15 7.90664 14.9183 7.74414 14.7558C7.41832 14.43 7.41832 13.9033 7.74414 13.5775L11.3217 10L7.74418 6.4225C7.41836 6.09668 7.41836 5.57 7.74418 5.24418C8.07 4.91836 8.59668 4.91836 8.9225 5.24418L13.0892 9.41086C13.415 9.73668 13.415 10.2633 13.0892 10.5892Z"
                            fill="#2D69F6" />
                    </g>
                    <defs>
                        <clipPath id="clip0_8765_5184">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </a>
            <a class="flex border-[#E6E8EC] border-b items-center px-[1rem] py-[1.5rem] cursor-pointer"
                href="https://www.digitalpermitbook.com/driver-help" target="_blank">
                <div>
                    <p class="mb-0">Online Help Library</p>
                </div>
                <svg width="20" class="ml-auto cursor-pointer" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_8765_5184)">
                        <path
                            d="M10 0C4.48582 0 0 4.48582 0 10C0 15.5142 4.48582 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 0 10 0ZM13.0892 10.5892L8.9225 14.7558C8.76 14.9183 8.54668 15 8.33332 15C8.12 15 7.90664 14.9183 7.74414 14.7558C7.41832 14.43 7.41832 13.9033 7.74414 13.5775L11.3217 10L7.74418 6.4225C7.41836 6.09668 7.41836 5.57 7.74418 5.24418C8.07 4.91836 8.59668 4.91836 8.9225 5.24418L13.0892 9.41086C13.415 9.73668 13.415 10.2633 13.0892 10.5892Z"
                            fill="#2D69F6" />
                    </g>
                    <defs>
                        <clipPath id="clip0_8765_5184">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    name: "HelpComponent",
    data() {
        return {
            window
        }
    },
}
</script>