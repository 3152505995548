<template>
    <div id="assign-equipment-modal" tabindex="-1" aria-hidden="true"
        class="bg-black bg-opacity-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100vh] max-h-full flex">
        <div class="relative w-[312px] max-h-full bg-white rounded-[16px]">
            <!-- Modal content -->
            <div class="relative bg-white shadow rounded-[16px]">
                <div class="p-4 md:p-5 text-center">
                    <svg width="52" height="52" viewBox="0 0 52 52" class="mx-auto" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_8765_2313)">
                            <path
                                d="M50.8889 10.4734C49.9109 6.05748 45.63 2.01225 41.2019 1.15303C38.5034 0.613732 30.9766 -0.0525175 26 -0.000720668C21.0234 -0.0555644 13.4977 0.608654 10.7991 1.14998C6.37102 2.01225 2.09016 6.05748 1.11719 10.4734C0.555547 13.1547 0.008125 16.8028 0 21.7824C0.008125 26.759 0.555547 30.4081 1.11719 33.0904C2.09523 37.5063 6.37609 41.5515 10.8042 42.4108C12.2606 42.7012 15.1257 43.0303 18.2355 43.2619C18.5876 43.815 18.9702 44.4041 19.3832 45.029C21.0336 47.5295 22.296 49.2713 23.2537 50.485C24.8463 52.504 27.1639 52.504 28.7564 50.485C29.7141 49.2713 30.9766 47.5295 32.627 45.029C33.04 44.4048 33.4225 43.8157 33.7746 43.2619C36.8855 43.0303 39.7495 42.7012 41.207 42.4108C45.6351 41.5515 49.9159 37.5063 50.894 33.0904C51.4505 30.4081 51.997 26.761 52.0061 21.7824C51.9919 16.8018 51.4455 13.1557 50.8889 10.4734Z"
                                fill="#F9973E" />
                            <path
                                d="M26 -0.000720668C21.0234 -0.0555644 13.4977 0.608654 10.7991 1.14998C6.37102 2.01225 2.09016 6.05748 1.11719 10.4734C0.555547 13.1547 0.008125 16.8028 0 21.7824C0.008125 26.759 0.555547 30.4081 1.11719 33.0904C1.69305 35.6894 3.41352 38.1604 5.63469 39.9286C7.80133 40.4473 10.0216 40.7088 12.2495 40.7076C27.8525 40.7076 40.5011 28.059 40.5011 12.4569C40.5047 8.39372 39.6281 4.37794 37.9316 0.685842C34.4612 0.295842 29.574 -0.0372832 26 -0.000720668Z"
                                fill="#FCA84C" />
                            <path
                                d="M28.8722 36.8799C28.8048 37.1963 28.6472 37.4863 28.4185 37.715C28.1898 37.9437 27.8998 38.1013 27.5834 38.1687C26.5343 38.3299 25.4668 38.3299 24.4177 38.1687C24.1013 38.1014 23.8112 37.9439 23.5825 37.7152C23.3537 37.4864 23.1962 37.1963 23.1289 36.8799C22.9664 35.8306 22.9664 34.7625 23.1289 33.7132C23.1962 33.3968 23.3537 33.1067 23.5825 32.878C23.8112 32.6492 24.1013 32.4917 24.4177 32.4244C25.4668 32.2632 26.5343 32.2632 27.5834 32.4244C27.9003 32.4921 28.1907 32.6503 28.4195 32.8798C28.6482 33.1093 28.8055 33.4002 28.8722 33.7173C29.0347 34.7666 29.0347 35.8347 28.8722 36.884V36.8799ZM35.0554 20.245C34.2713 21.3541 33.0962 22.3666 31.3575 23.43C29.1566 24.7869 28.8042 25.3445 28.8042 26.4362V26.8679C28.8113 27.2411 28.7439 27.612 28.606 27.9588C28.4681 28.3057 28.2624 28.6216 28.001 28.888C27.7396 29.1545 27.4277 29.3661 27.0835 29.5106C26.7393 29.6551 26.3698 29.7295 25.9965 29.7295C25.6232 29.7295 25.2537 29.6551 24.9095 29.5106C24.5654 29.3661 24.2534 29.1545 23.992 28.888C23.7306 28.6216 23.5249 28.3057 23.387 27.9588C23.2491 27.612 23.1817 27.2411 23.1888 26.8679V25.8675C23.1888 24.5797 23.5108 23.4869 24.1729 22.5271C24.8148 21.5968 25.7776 20.7701 27.2036 19.922C29.833 18.3752 30.3144 17.5069 30.3144 16.3541C30.3144 14.9059 29.0805 13.9695 27.166 13.9695C25.0748 13.9695 23.7129 14.9201 23.1187 16.7959C22.9262 17.3994 22.5459 17.9255 22.0332 18.2976C21.5205 18.6696 20.9023 18.8679 20.2689 18.8637C20.1958 18.8637 20.1216 18.8637 20.0495 18.8556C19.5945 18.8236 19.1528 18.6879 18.7581 18.4591C18.3635 18.2303 18.0264 17.9143 17.7725 17.5353C17.5188 17.1631 17.3536 16.7377 17.2896 16.2919C17.2255 15.846 17.2642 15.3913 17.4028 14.9627C18.0122 13.0095 19.2619 11.3186 20.9504 10.1629C22.6526 9.00305 24.8026 8.39062 27.166 8.39062C29.6208 8.39062 31.8988 9.17367 33.5807 10.5955C35.3123 12.058 36.265 14.0761 36.265 16.277C36.2701 17.7902 35.873 19.0923 35.0554 20.245Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_8765_2313">
                                <rect width="52" height="52" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <h2 class="font-bold text-[20px] text-[#11004D] mt-3">Confirmation</h2>
                    <p class="text-[#11004D] opacity-50 mt-2">Do you want to unassign this {{ equipment }}?</p>
                </div>
                <!-- Modal footer -->
                <div class="p-4">
                    <button
                        class="text-[#fff] h-[48px] text-[14px] flex justify-center w-full rounded-[12px] bg-[#2D69F6] items-center"
                        @click="setUnassignEquipmentModal(true)"
                        >Confirm</button>
                    <button type="button"
                        class="text-[#000] mt-3 h-[48px] text-[14px] flex justify-center w-full rounded-[12px] bg-transparent border border-[#DADADA] items-center mb-3"
                        @click="setUnassignEquipmentModal()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "UnAssignEquipmentComponent",
    props: ['equipment', 'setUnassignEquipmentModal']
}
</script>