<template>
    <div>
        <div class="px-[1rem] lg:px-[0rem] mt-[1rem] text-center" id="headerSection">
            <div class="relative">
                <input :placeholder="`Search for a ${$route.name === 'Vehicles' ? 'vehicle' : 'trailer'}`"
                    v-model="searchEquipment" @blur="getFilteredEquipments()"
                    class="rounded-[12px] text-[#1B1D21] border border-[rgba(17,0,77,0.1)] px-3 py-4 w-full placeholder-[rgba(17,0,77,0.1)] focus:outline-none">
                <svg class="absolute top-[20px] right-[15px]" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_8765_2602)">
                        <path
                            d="M19.8105 18.9119L14.6468 13.8308C15.999 12.3616 16.8298 10.4187 16.8298 8.28068C16.8292 3.7071 13.0621 0 8.41471 0C3.76737 0 0.000244141 3.7071 0.000244141 8.28068C0.000244141 12.8543 3.76737 16.5614 8.41471 16.5614C10.4227 16.5614 12.2644 15.8668 13.711 14.7122L18.8947 19.8134C19.1473 20.0622 19.5573 20.0622 19.8099 19.8134C20.0631 19.5646 20.0631 19.1607 19.8105 18.9119ZM8.41471 15.2873C4.48256 15.2873 1.29493 12.1504 1.29493 8.28068C1.29493 4.41101 4.48256 1.27403 8.41471 1.27403C12.3469 1.27403 15.5345 4.41101 15.5345 8.28068C15.5345 12.1504 12.3469 15.2873 8.41471 15.2873Z"
                            fill="#E7E5ED" />
                    </g>
                    <defs>
                        <clipPath id="clip0_8765_2602">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <svg width="2" class="absolute top-[13px] right-[50px]" height="32" viewBox="0 0 2 32" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.1" d="M1 0V32" stroke="#11004D" />
                </svg>

            </div>
            <div class="flex mt-4">
                <button class="h-[36px] rounded-[4px] px-5 py-3 flex items-center justify-center"
                    :class="status === 'all' ? 'active-btn' : 'non-active-btn'" @click="status = 'all'">All</button>
                <button class="h-[36px] ml-2 rounded-[4px] px-5 py-3 flex items-center justify-center"
                    :class="status === 'available' ? 'active-btn' : 'non-active-btn'"
                    @click="status = 'available'">Available</button>
            </div>
        </div>
        <div class="relative">
            <div class="mt-5 overflow-y-auto" id="infinite-list"
                :style="[equipmentSectionHeight ? { 'height': equipmentSectionHeight + 'px' } : {}]">
                <div class="flex border-t border-[#E6E8EC] border-b items-center px-8 py-5 cursor-pointer"
                    v-for="(item, index) in equipmentData" :key="index">
                    <div class="" @click="$route.name === 'Vehicles' ? $router.push('/vehicle-details/'+item.id):$router.push('/trailer-details/'+item.id)">
                        <p class="font-medium">{{ item.unit_no }}</p>
                        <p class="text-[#23262F] opacity-50 text-[12px] w-[200px] sm:w-full">{{ item.tags.map((tag, index) =>
                            tag.add_tag).join(' . ') }}</p>
                    </div>
                    <button class="px-4 py-2 ml-auto rounded-[4px] text-[12px] w-[80px]"
                    @click="setEquipmentModal(item)"
                        :class="item.status === 'Active' ? 'bg-[#2D69F6] text-[#fff]' : item.status === 'Assigned' && (($route.name === 'Vehicles' && user.team_member.default_vehicle && user.team_member.default_vehicle.id === item.id) || ($route.name === 'Trailers' && user.team_member.default_trailer && user.team_member.default_trailer.id === item.id)) ? 'bg-[#2CCE89] text-[#fff]':'bg-[#EAF0FF] text-[#2D69F6]'">
                        {{ item.status }}
                    </button>
                </div>
            </div>
        </div>
        <AssignEquipmentComponent v-if="showAssignEquipmentModal" :setAssignEquipmentModal="setAssignEquipmentModal"
            :equipment="`${$route.name === 'Vehicles' ? 'vehicle' : 'trailer'}`" 
            :equipmentItem="equipmentItem"
            />
        <AlreadyAssignedEquipmentComponent v-if="showAlreadyAssignEquipmentModal"
            :setAlreadyAssignEquipmentModal="setAlreadyAssignEquipmentModal"
            :equipment="`${$route.name === 'Vehicles' ? 'vehicle' : 'trailer'}`" />
    </div>
</template>
<script>
import AssignEquipmentComponent from "@/components/Equipment/AssignEquipment"
import AlreadyAssignedEquipmentComponent from "@/components/Equipment/AlreadyAssignedEquipment"
import { mapActions, mapGetters } from "vuex";
export default {
    name: "SearchEquipment",
    components: { AssignEquipmentComponent, AlreadyAssignedEquipmentComponent },
    data() {
        return {
            status: "all",
            equipmentData: [],
            showAssignEquipmentModal: false,
            currentPage: 1,
            totalPages: 1,
            equipmentItem:null,
            searchEquipment: "",
            showAlreadyAssignEquipmentModal: false,
            equipmentSectionHeight: null
        }
    },
    computed: {
        ...mapGetters(["user"]),
    },
    watch: {
        status() {
            this.currentPage = 1
            this.equipmentData = []
            this.getEquipmentData()
        },
    },
    methods: {
        ...mapActions(["getAllEquipments", "setLoader", "updateTeamMember", "setNotification","setAppStatus"]),
        setEquipmentModal(data){
            if(((this.$route.name === 'Vehicles' && this.user.team_member.default_vehicle && this.user.team_member.default_vehicle.id === data.id) || (this.$route.name === 'Trailers' && this.user.team_member.default_trailer && this.user.team_member.default_trailer.id === data.id))) return
            this.equipmentItem = data; 
            data.status === 'Active' ? this.showAssignEquipmentModal = true : this.showAlreadyAssignEquipmentModal = true

        },
        getFilteredEquipments() {
            this.currentPage = 1
            this.equipmentData = []
            this.getEquipmentData()
        },
        async setAssignEquipmentModal(assign = false) {
            if (assign) {
                try {
                    this.setLoader(true)
                    await this.updateTeamMember({
                        id: this.user.team_member.id,
                        payload: { ...this.$route.name === 'Vehicles' ? { default_vehicle: this.equipmentItem.id } : { default_trailer: this.equipmentItem.id } },
                        companyID: this.user.team_member.member_company.id
                    });
                    this.setNotification({
                        msg: `${this.$route.name} assigned succesfully.`,
                        type: "success",
                        color: "green",
                    })
                    this.setLoader(false)
                    this.$router.push("/")
                    this.setAppStatus(true)
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            }
            this.showAssignEquipmentModal = !this.showAssignEquipmentModal

        },
        async setAlreadyAssignEquipmentModal(unassign = false) {
            if(unassign){
                this.setLoader(true)
                    await this.updateTeamMember({
                        id:  this.equipmentItem.driver.id,
                        payload: { ...this.$route.name === 'Vehicles' ? { default_vehicle: null } : { default_trailer: this.null } },
                        companyID: this.user.team_member.member_company.id
                    });
                    await this.updateTeamMember({
                        id: this.user.team_member.id,
                        payload: { ...this.$route.name === 'Vehicles' ? { default_vehicle: this.equipmentItem.id } : { default_trailer: this.equipmentItem.id } },
                        companyID: this.user.team_member.member_company.id
                    });
                    this.setNotification({
                        msg: `${this.$route.name} assigned succesfully.`,
                        type: "success",
                        color: "green",
                    })
                    this.setLoader(false)
                    this.$router.push("/")
                    this.setAppStatus(true)
            }
            this.showAlreadyAssignEquipmentModal = !this.showAlreadyAssignEquipmentModal
        },
        async getEquipmentData() {
            try {
                this.setLoader(true)
                let url = ""
                if (this.searchEquipment) {
                    url += `search_field=${this.searchEquipment}`
                }
                url += `status=${this.status === 'all' ? 'Active,Assigned' : 'Active'}&type=${this.$route.name === 'Vehicles' ? 'Vehicle' : 'Trailer'}&sort_field=unit_no&sort_order=asc&page=${this.currentPage}&page_size=10`
                const data = await this.getAllEquipments({ url, companyID: this.user.team_member.member_company.id });
                this.totalPages = data.total_pages
                data.records.map((item) => {
                    this.equipmentData.push({
                        id: item.id,
                        unit_no: item.unit_no,
                        tags: item.tags,
                        status: item.status,
                        driver: item.driver ? item.driver:null
                    })
                })
                this.setLoader(false)
            }
            catch (e) {
                this.setLoader(false)
            }
        },
        updateEquipmentAreaSize() {
            this.equipmentSectionHeight = document.querySelector("body").clientHeight - 240
        },
        addInfiniteScroll() {
            const listElm = document.querySelector('#infinite-list');
            listElm.addEventListener('scroll', () => {
                if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight && this.currentPage < this.totalPages) {
                    this.currentPage++
                    this.getEquipmentData()
                }
            });
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.updateEquipmentAreaSize);
        document.querySelector('body').classList.remove("overflow-y-hidden")
        const listElm = document.querySelector('#infinite-list');
        if(listElm) listElm.removeEventListener("scroll", this.updateEquipmentAreaSize);
    },
    async mounted() {
        this.addInfiniteScroll()
        this.getEquipmentData()
        document.querySelector('body').classList.add("overflow-y-hidden")
        await this.$nextTick();
        this.updateEquipmentAreaSize()
        window.addEventListener("resize", this.updateEquipmentAreaSize);
    }
}
</script>
<style>
.active-btn {
    background-color: #2D69F6;
    border-color: #2D69F6;
    color: #fff;
    font-size: 14px;
}

.non-active-btn {
    color: #23262F;
    opacity: 0.5;
    border: 1px solid rgba(35, 38, 47, 0.36);
    font-size: 14px;
}
</style>