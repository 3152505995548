import { getReq, postReq, patchReq } from "../../../services/api";

const state = {
  user: null,
  refreshApp:false
};

const getters = {
  user: (state) => state.user,
  refreshApp:(state)=>state.refreshApp
};

const actions = {
  async loginUser(_, { payload }) {
    try {
      const response = await postReq("login/", { ...payload, "app": "True" });
      if (response.role === 'Driver') {
        if (response.key) {
          localStorage.setItem("driver-token", response.key);
        }
        return response;
      }
      else throw { "non_field_errors": ["Only Drivers are allowed to use this app."] }
    } catch (e) {
      const errors = e?.response?.data || e
      const error = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          error.push(errors[key]);
        }
      }

      throw error[0][0];
    }
  },
  async logoutUser() {
    localStorage.clear();
  },
  async getUser({ commit }) {
    try {
      const user = await getReq("app/users/");

      commit("setUser", user);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  setUserData({ commit }, user) {
    commit("setUser", user);
  },
  setAppStatus({ commit }, status) {
    commit("setAppStatus", status);
  },
  async getUserByID({ commit }) {
    try {
      let user
      let { id, version } = await getReq(`app/users/`);
      if (id) {
        user = await getReq(`user/${id}`);
      }
      commit("setUser", { ...user, version });
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async resetPassword(_, email) {
    try {
      const form = new FormData();
      form.append("email", email);
      const response = await postReq("password/reset/", form);
      return response;
    } catch (e) {
      const errors = e.response.data;
      const error = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          error.push(errors[key]);
        }
      }
      throw error[0][0];
    }
  },
  async updateTeamMember(_, { id, payload, companyID }) {
    try {
      await patchReq("app/team-members", id, payload, companyID);
    } catch (e) {
      console.log(e);
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      throw errors;
    }
  },
  async setUserDotMode(_, payload) {
    try {
      return await postReq("app/start-dot-mode/", payload);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
};

const mutations = {
  setUser: (state, user) => (state.user = user),
  setAppStatus: (state, status) => (state.refreshApp = status),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
