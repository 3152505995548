import Vuex from "vuex";
import Vue from "vue";

import user from "./modules/user"
import notification from "./modules/notification"
import loader from "./modules/loader"
import company from "./modules/company";
import equipment from "./modules/equipment";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    notification,
    loader,
    equipment,
    company
  },
});