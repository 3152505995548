<template>
    <div class="container mx-auto relative">
        <svg class="absolute top-[-40px] left-[10px] cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg" @click="$router.push('/login')">
            <path
                d="M18.6661 10.6666H8.54191L12.9437 6.28357C13.1949 6.03271 13.336 5.69246 13.336 5.33769C13.336 4.98291 13.1949 4.64266 12.9437 4.3918C12.6926 4.14093 12.3519 4 11.9967 4C11.6415 4 11.3008 4.14093 11.0496 4.3918L4.38018 11.053C4.25874 11.1797 4.16355 11.3291 4.10006 11.4926C3.96665 11.817 3.96665 12.1808 4.10006 12.5051C4.16355 12.6686 4.25874 12.818 4.38018 12.9448L11.0496 19.6059C11.1736 19.7308 11.3211 19.8299 11.4837 19.8975C11.6462 19.9652 11.8206 20 11.9967 20C12.1728 20 12.3471 19.9652 12.5097 19.8975C12.6722 19.8299 12.8197 19.7308 12.9437 19.6059C13.0688 19.4821 13.168 19.3347 13.2357 19.1724C13.3034 19.01 13.3383 18.8359 13.3383 18.66C13.3383 18.4842 13.3034 18.31 13.2357 18.1477C13.168 17.9853 13.0688 17.838 12.9437 17.7142L8.54191 13.3311H18.6661C19.0199 13.3311 19.3592 13.1907 19.6093 12.9409C19.8595 12.6911 20 12.3522 20 11.9989C20 11.6455 19.8595 11.3067 19.6093 11.0568C19.3592 10.807 19.0199 10.6666 18.6661 10.6666Z"
                fill="black" />
        </svg>
        <div class="px-[1rem] lg:px-[0rem] mt-[5rem]">
            <h2 class="text-[36px] font-bold">Forget Password</h2>
            <p class="text-[24px] mt-3 leading-[34px] opacity-50">Enter your email address <br
                    class="sm:block md:hidden" />to
                reset password.</p>
            <form @submit.prevent="handleFormSubmit">
                <div class="relative mt-8">
                    <input type="email" id="floating_outlined"
                        class="rounded-[16px] block px-2.5 pb-2.5 pt-4 w-full text-[12px] bg-transparent rounded-lg border border-[#DADADA] appearance-none focus:outline-none focus:ring-0 focus:border-[#2D69F6] peer"
                        placeholder="" required v-model="email" />
                    <label for="floating_outlined"
                        class="absolute text-[12px] text-[#DADADA] duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1">Enter
                        Email</label>
                </div>
                <div class="mt-5">
                    <button type="submit"
                        class="text-[#fff] h-[56px] text-[14px] flex justify-center w-full rounded-[16px] bg-[#2D69F6] items-center">Reset
                        Password</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "ForgotPasswordComponent",
    data() {
        return {
            email: ""
        }
    },
    methods: {
        ...mapActions(["resetPassword", "setNotification"]),
        async handleFormSubmit() {
            try {
                const { detail } = await this.resetPassword(this.email);
                if (detail) {
                    this.setNotification({
                        msg: "Password reset e-mail has been sent.",
                        type: "success",
                        color: "green",
                    });
                    this.$router.push("/login");
                }
            } catch (error) {
                this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        }
    }
}
</script>