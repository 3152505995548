<template>
    <div class="noti-container">
      <div :class="notification.color" class="w-[90%] sm:w-[50%] p-3" v-if="notification.msg">
        {{ notification.msg }}
  
        <button
          class="closebtn text-[#000]"
          text
          @click="closeSnackBar"
        >
          X
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  export default {
    name: "NotificationComponent",
    methods: {
      ...mapActions(["setNotification"]),
      closeSnackBar() {
        this.setNotification({
          type: null,
          msg: null,
          color: null,
        });
      },
    },
    computed: mapGetters(["notification"]),
    async mounted() {
      setTimeout(() => {
        this.setNotification({
          type: null,
          msg: null,
          color: null,
        });
      }, 8000);
    },
    destroyed() {
      this.setNotification({
        type: null,
        msg: null,
        color: null,
      });
    },
  };
  </script>
  
  <style>
  .noti-container {
    height: 100px;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    position: fixed;
    width: 100%;
    z-index: 1056;
  }
  .red {
    background-color: #FF0000;
    color: white;
    width: 50%;
    margin: auto;
    padding: 1%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-self: center;
  }
  .green {
    background-color: #3aca76;
    color: white;
    width: 50%;
    margin: auto;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-self: center;
  }
  .closebtn {
    background-color: white;
    border: none;
    height: 20px;
    width: 20px;
    border-radius: 51%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: small;
  }
  
  </style>
  